import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";

import Logo from "./assets/logo.svg";
import PhoneIcon from "./assets/phoneIcon.svg";
import CloseIcon from "./assets/close-icon.svg";
import Burger from "./assets/burger.svg";
import { NavbarStyled, NavbarWrapper } from "./Navbar.style";
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";
import DemoModal from "../DemoModal/DemoModal";

const Navbar = () => {
  const { i18n, t } = useTranslation();
  const matches = useMediaQuery("(max-width: 768px)");

  const openNavbar = () => {
    let burger = document.getElementById("mobile-menu");
    if (burger) {
      burger.style.transform = "translate(0)";
    }
  };

  const closeNavbar = () => {
    let burger = document.getElementById("mobile-menu");
    if (burger) {
      burger.style.transform = "translate(-130%)";
    }
  };

  const handleChangeLanguage = (event: any) => {
    i18n.changeLanguage(event.target.id);
  };

  useEffect(() => {
    if (matches) {
      closeNavbar();
    } else {
      openNavbar();
    }
  }, [matches]);

  const language = localStorage.getItem("i18nextLng");

  return (
    <NavbarWrapper>
      <NavbarStyled className="">
        <div className="logo">
          <Link to="/">
            <img className="logo" src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="burger">
          <img src={Burger} alt="burger" onClick={openNavbar} />
        </div>
        <div className="actions-wrapper">
          <div className="actions" id="mobile-menu">
            <div className="mobile-menu">
              <div className="top">
                <Link to="/">
                  <img className="mobile-logo" src={Logo} alt="logo" />
                </Link>
                <img
                  className="pointer"
                  src={CloseIcon}
                  alt="logo"
                  onClick={closeNavbar}
                />
              </div>
              <div className="btns">
                <button
                  className={`pointer me-4 ${
                    language === "uz" ? "btn-active" : ""
                  }`}
                  id="uz"
                  onClick={handleChangeLanguage}
                >
                  O'zbek
                </button>
                <button
                  className={`pointer ${language === "ru" ? "btn-active" : ""}`}
                  id="ru"
                  onClick={handleChangeLanguage}
                >
                  Русский
                </button>
              </div>
            </div>
            <ul className="list">
              <li className="item">
                <a href="#product" onClick={() => matches && closeNavbar()}>
                  {t("NAVBAR.PRODUCT")}
                </a>
              </li>
              <li className="item">
                <a href="#chance" onClick={() => matches && closeNavbar()}>
                  {t("NAVBAR.CHANCES")}
                </a>
              </li>
              {/* <li className="item">
                <a href="#tariff" onClick={() => matches && closeNavbar()}>
                  {t("NAVBAR.TARIFFS")}
                </a>
              </li> */}
              <li className="item">
                <a href="#about" onClick={() => matches && closeNavbar()}>
                  {t("NAVBAR.VIEWS")}
                </a>
              </li>
            </ul>
            <div className="language">
              <ChangeLanguage />
            </div>
          </div>
          <DemoModal />
        </div>
      </NavbarStyled>
    </NavbarWrapper>
  );
};

export default Navbar;
