import React from "react";

const Arrow = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15_480)">
        <path
          d="M1.18896 3.85001L7.33896 10C7.38385 10.0478 7.43807 10.086 7.49827 10.112C7.55847 10.1381 7.62337 10.1515 7.68896 10.1515C7.75456 10.1515 7.81946 10.1381 7.87966 10.112C7.93986 10.086 7.99407 10.0478 8.03896 10L14.189 3.85001"
          stroke="#000"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15_480">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.688965)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Arrow;
