import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { TryStyled } from "./Try.style";
import Dots from "./assets/dots.svg";
import DemoModal from "../DemoModal/DemoModal";

const Try = () => {
  const { t } = useTranslation();

  return (
    <TryStyled className="container">
      <img src={Dots} alt="dots" className="dots" />
      <div className="bg-left"></div>
      <Grid container className="list">
        <Grid item xs={12} md={5}>
          <h2 className="section-title">{t("TRY.TITLE")}</h2>
          <p className="description">{t("TRY.DESCRIPTION")}</p>
        </Grid>
        <Grid item xs={12} md={6.5}>
          <div className="bg-right"></div>
          <div className="bg-right-mobile"></div>
          <div className="input-wrapper">
            <DemoModal />
            {/* <input
              type="number"
              className="input"
              placeholder={t("TRY.ENTER_PHONE_NUMBER")}
              onWheel={(e) => e.currentTarget.blur()}
            />
            <button className="send-btn">{t("TRY.SEND")}</button> */}
          </div>
        </Grid>
      </Grid>
    </TryStyled>
  );
};

export default Try;
