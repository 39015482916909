import styled from "styled-components";

export const FooterStyled = styled.div`
  margin-top: 150px;
  padding: 56px 0;
  background-color: ${({ theme }) => theme.primary.light};
  .location,
  .phone {
    display: flex;
    align-items: flex-start;
    p {
      margin-left: 12px;
      font-weight: 500;
      font-size: 20px;
      color: #fff;
    }
  }
  .location {
    max-width: 245px;
  }
  .phone {
    margin-bottom: 12px;
  }
  .number {
    font-weight: 700;
    font-size: 20px;
    color: #fff;
  }
  .social-network {
    font-weight: 500;
    font-size: 20px;
    color: #fff;
    margin-bottom: 12px;
  }
  .links {
    display: flex;
    a {
      margin-right: 20px;
    }
  }
  @media (max-width: 600px) {
    padding: 40px 0;
    margin-top: 90px;
    .logo {
      margin-bottom: 30px;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
