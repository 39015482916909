import styled from "styled-components";

export const DemoModalStyled = styled.div`
  .btn-wrapper {
    button {
      transition: all ease 0.2s;
      -webkit-box-shadow: 2px 3px 2px 0px rgba(0, 0, 0, 0.17);
      -moz-box-shadow: 2px 3px 2px 0px rgba(0, 0, 0, 0.17);
      box-shadow: 2px 3px 2px 0px rgba(0, 0, 0, 0.17);
      transition: all ease 0.2s;
      position: relative;
      overflow: hidden;
    }

    button::after {
      content: "";
      width: 30px;
      height: 200px;
      position: absolute;
      left: -30px;
      top: 0;
      margin-top: -20px;
      background: #fff;
      opacity: 0.5;
      filter: blur(1px);
      animation: login-blur 3s infinite;
      animation-delay: 1s;
      transform-origin: top;
    }

    @keyframes login-blur {
      from {
        transform: translate(0) rotate(45deg);
      }
      to {
        transform: translateX(800px) rotate(45deg);
      }
    }
  }
  .modal {
    position: fixed;
    /* background-color: rgba(0, 0, 0, 0.6); */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    iframe {
      width: 100%;
      height: 100%;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  .modal-content {
    max-width: 476px;
    width: 80%;
    background-color: #64cdff;
    border-radius: 12px;
    padding: 32px 32px 47px 32px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 55px;
      h2 {
        color: #fff;
        font-size: 24px;
      }
    }
  }
  .input {
    margin-bottom: 32px;
  }
  form {
    .custom-button {
      display: block;
      width: 100%;
      padding: 16px 0;
      margin-top: 60px;
      transition: all 0.3s;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
