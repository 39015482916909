import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import { AboutStyled } from "./About.style";
import NextIcon from "../../../../assets/NextIcon";
import PreviousIcon from "../../../../assets/PreviousIcon";
import ClientImg1 from "./assets/client-say-1.jpg";
import ClientImg2 from "./assets/client-say-2.jpg";
import ClientImg3 from "./assets/client-say-3.jpg";
import Istamov from "./assets/istamov.jpg";
import Dots from "./assets/dots.svg";

const About = () => {
  const { t } = useTranslation();

  const images = [ClientImg1, Istamov, ClientImg3];

  const renderPrevButton = () => {
    return (
      <button className="slick-arrow slick-prev">
        <PreviousIcon />
      </button>
    );
  };
  const renderNextButton = () => {
    return (
      <button className="slick-arrow slick-next">
        <NextIcon />
      </button>
    );
  };

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    fade: true,
    speed: 800,
    appendDots: (dots: any) => <ul>{dots}</ul>,
    customPaging: (i: any) => (
      <div className="dot-image-wrapper">
        <img src={images[i]} alt="client" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          customPaging: (i: any) => (
            <div className="dot-image-wrapper">
              <div className="dot-paging"></div>
            </div>
          ),
        },
      },
    ],
  };

  return (
    <AboutStyled className="container" id="about">
      <h2 className="section-title">{t("ABOUT.TITLE")}</h2>
      <div className="slider">
        <Slider {...settings}>
          <div className="item">
            <div className="image-wrapper">
              <img src={Dots} alt="dots" className="dots" />
              <img
                src={ClientImg1}
                alt="client image"
                className="client-image"
              />
              <div className="ellipse"></div>
            </div>
            <div>
              <h4 className="name">Avazbek Tulovov</h4>
              <p className="view">
                O'quv markazlarini boshqarish va rivojlantirish uchun ajoyib
                platforma. Rahbarni operatsion jarayonlardan chiqib ko'proq
                diqqatini strategik rivojlanish uchun imkon beradi.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="image-wrapper">
              <img src={Dots} alt="dots" className="dots" />
              <img src={Istamov} alt="client image" className="client-image" />
              <div className="ellipse"></div>
            </div>
            <div>
              <h4 className="name">Istamov Muhammadjon</h4>
              <p className="view">
                Edutizim platformasi orqali o'quv markazimizda sotuv bo'limini
                ishlarini tizimlashtirishda, o'quvchilarni keldi-ketdisini
                nazorat qilish, o'qituvchilarni oyliklarini hisoblashda ancha
                ishimiz yengillashdi. Eng asosiysi hech kim haqqi boshqa birovga
                o'tib ketmasligi juda yoqdi. Bu narsa ishni o'sishiga, barakaga
                sabab bo'ladi inshAlloh. Sizga ham Edutizim platformasidan
                foydalanishni tavsiya qilaman.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="image-wrapper">
              <img src={Dots} alt="dots" className="dots" />
              <img
                src={ClientImg3}
                alt="client image"
                className="client-image"
              />
              <div className="ellipse"></div>
            </div>
            <div>
              <h4 className="name">Sayfullaev Murodilla</h4>
              <p className="view">
                Edutizim darsturini ishlatishni boshlaganimga hali ko'p bo'lmadi
                lekin shunga qaramasdan ancha foydasi tegayotganini sezdim.
                Masalan ota onalar bilan aloqani ushash, yo'qlamadagi
                kamchiliklar va qabul bo'limidagi muammolar o'z yechimini
                topmoqda. O'quv markazingizni tizimlashtiring hali ham kech
                emas!
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </AboutStyled>
  );
};

export default About;
