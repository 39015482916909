import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { PlatformStyled } from "./Platform.style";
import Platform1 from "./assets/platform1.svg";
import Platform2 from "./assets/platform2.svg";
import Platform3 from "./assets/platform3.svg";
import Platform4 from "./assets/platform4.svg";
import Dots from "./assets/dots.svg";
import Ellipse from "./assets/ellipse.svg";

const Platform = () => {
  const { t } = useTranslation();

  return (
    <PlatformStyled id="chance">
      <div className="bg-right"></div>
      <div className="container">
        <div className="bg-left"></div>
        <img src={Dots} alt="dots" className="dots" />
        <img src={Ellipse} alt="ellipse" className="ellipse" />
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md={3.5} lg={4} className="text">
            <h2 className="section-title">{t("PLATFORM.TITLE")}</h2>
            <p className="description">{t("PLATFORM.DESCRIPTION")}</p>
          </Grid>
          <Grid item xs={12} md={8} lg={7} className="cards">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} className="card-wrapper">
                <div className="card">
                  <div className="image-wrapper image-wrapper1">
                    <img src={Platform1} alt="platform image" />
                  </div>
                  <h3>{t("PLATFORM.CARD_TITLE1")}</h3>
                  <p>{t("PLATFORM.CARD_DESCRIPTION1")}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} className="card-wrapper">
                <div className="card">
                  <div className="image-wrapper image-wrapper2">
                    <img src={Platform2} alt="platform image" />
                  </div>
                  <h3>{t("PLATFORM.CARD_TITLE2")}</h3>
                  <p>{t("PLATFORM.CARD_DESCRIPTION2")}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} className="card-wrapper">
                <div className="card">
                  <div className="image-wrapper image-wrapper3">
                    <img src={Platform3} alt="platform image" />
                  </div>
                  <h3>{t("PLATFORM.CARD_TITLE3")}</h3>
                  <p>{t("PLATFORM.CARD_DESCRIPTION3")}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} className="card-wrapper">
                <div className="card">
                  <div className="image-wrapper image-wrapper4">
                    <img src={Platform4} alt="platform image" />
                  </div>
                  <h3>{t("PLATFORM.CARD_TITLE4")}</h3>
                  <p>{t("PLATFORM.CARD_DESCRIPTION4")}</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </PlatformStyled>
  );
};

export default Platform;
