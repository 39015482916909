import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import { IntroStyled } from "./Intro.style";
import GooglePlay from "./assets/google-play.svg";
import AppStore from "./assets/app-store.svg";
import Phone1 from "../../../../assets/phone1.png";
import Phone2 from "../../../../assets/phone2.png";
import Phone3 from "../../../../assets/phone3.png";
import Phone4 from "../../../../assets/phone4.png";
import DemoModal from "../DemoModal/DemoModal";

const Intro = () => {
  const { t } = useTranslation();

  return (
    <IntroStyled>
      <div className="container">
        <div className="text">
          <h1>{t("INTRO.TITLE")}</h1>
          <p className="description">{t("INTRO.DESCRIPTION")}</p>
          <div className="demo-modal">
            <DemoModal />
          </div>
          <div className="download">
            <div data-aos="zoom-in" data-aos-duration="700">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=uz.unical.edusystem.moderator"
              >
                <img src={GooglePlay} alt="google play" />
              </a>
            </div>
            <div data-aos="zoom-in" data-aos-duration="700">
              <a
                target="_blank"
                href="https://apps.apple.com/uy/app/edu-tizim-moderator/id1612678923"
              >
                <img src={AppStore} alt="app store" />
              </a>
            </div>
          </div>
        </div>
        <div className="slider">
          <Slider
            dots={false}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={4000}
            infinite={true}
            speed={600}
            fade={true}
            centerPadding="20px"
          >
            <div className="item">
              <div
                className="circle circle1"
                data-aos="zoom-in"
                data-aos-duration="700"
              ></div>
              <img src={Phone1} alt="phone" />
            </div>
            <div className="item">
              <div
                className="circle circle2"
                data-aos="zoom-in"
                data-aos-duration="700"
              ></div>
              <img src={Phone2} alt="phone" />
            </div>
            <div className="item">
              <div
                className="circle circle3"
                data-aos="zoom-in"
                data-aos-duration="700"
              ></div>
              <img src={Phone3} alt="phone" />
            </div>
            <div className="item">
              <div
                className="circle circle4"
                data-aos="zoom-in"
                data-aos-duration="700"
              ></div>
              <img src={Phone4} alt="phone" />
            </div>
          </Slider>
        </div>
      </div>
    </IntroStyled>
  );
};

export default Intro;
