import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Home from "./pages/home/container";
import { LightTheme } from "./styles/theme/LightTheme";
import AOS from "aos";
import "aos/dist/aos.css";

const App: React.FC = () => {
  AOS.init();

  useEffect(() => {
    // window.location.href = "https://t.me/edutizim_bot";
  }, []);

  return (
    <>
      <Suspense fallback="">
        <ThemeProvider theme={LightTheme}>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </ThemeProvider>
      </Suspense>
    </>
  );
};

export default App;
