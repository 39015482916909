import styled from "styled-components";

export const ChangeLanguageStyled = styled.div`
  color: black;
  #demo-positioned-button {
    display: flex;
    align-items: center;
    background: #ffffff;
    /* border: 1px solid ${({ theme }) => theme.primary.light}; */
    border-radius: 8px;
    /* padding: 10px 12px; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    box-shadow: none !important;
    cursor: pointer;
    /* color: ${({ theme }) => theme.primary.light}; */
    color: #000;
    font-size: 17px;
    margin-top: 3px;
    svg{
      margin-left: 13px;
    }
    @media(max-width: 810px){
      padding: 8px;
      svg{
        margin-left: 10px;
      }
    }
  }
`;
