import { useTranslation } from "react-i18next";

import { AppsStyled } from "./Apps.style";
import Phone1 from "../../../../assets/phone1.png";
import Phone2 from "../../../../assets/phone2.png";
import Phone3 from "../../../../assets/phone3.png";
import Phone4 from "../../../../assets/phone4.png";

const Apps = () => {
  const { t } = useTranslation();
  return (
    <AppsStyled className="container" id="product">
      <h2 className="section-title">{t("APPS.TITLE")}</h2>
      <ul className="list">
        <li className="item">
          <div className="image-wrapper">
            <div
              className="circle circle1"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-duration="1800"
            ></div>
            <img src={Phone1} alt="phone" />
          </div>
          <div className="text">
            <h3 className="title">{t("APPS.CARD_TITLE1")}</h3>
            <p className="description">{t("APPS.CARD_DESCRIPTION1")}</p>
          </div>
        </li>
        <li className="item">
          <div className="image-wrapper">
            <div
              className="circle circle2"
              data-aos="fade-left"
              data-aos-offset="300"
              data-aos-duration="1800"
            ></div>
            <img src={Phone2} alt="phone" />
          </div>
          <div className="text">
            <h3 className="title">{t("APPS.CARD_TITLE2")}</h3>
            <p className="description">{t("APPS.CARD_DESCRIPTION2")}</p>
          </div>
        </li>
        <li className="item">
          <div className="image-wrapper">
            <div
              className="circle circle3"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-duration="1800"
            ></div>
            <img src={Phone3} alt="phone" />
          </div>
          <div className="text">
            <h3 className="title">{t("APPS.CARD_TITLE3")}</h3>
            <p className="description">{t("APPS.CARD_DESCRIPTION3")}</p>
          </div>
        </li>
        <li className="item">
          <div className="image-wrapper">
            <div
              className="circle circle4"
              data-aos="fade-left"
              data-aos-offset="300"
              data-aos-duration="1800"
            ></div>
            <img src={Phone4} alt="phone" />
          </div>
          <div className="text">
            <h3 className="title">{t("APPS.CARD_TITLE4")}</h3>
            <p className="description">{t("APPS.CARD_DESCRIPTION4")}</p>
          </div>
        </li>
      </ul>
    </AppsStyled>
  );
};

export default Apps;
