import styled from "styled-components";

export const NavbarWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(200, 200, 200, 0.5);
  z-index: 10;
`;

export const NavbarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  left: 0;
  right: 0;
  background-color: #fff;
  max-width: 1300px;
  margin: 0 auto;
  z-index: 10;
  .burger {
    display: none;
    margin-left: auto;
    cursor: pointer;
  }
  .actions-wrapper {
    display: flex;
    align-items: center;
  }
  .actions {
    display: flex;
    align-items: center;
  }
  .mobile-menu {
    display: none;
  }
  .mobile-logo {
    width: 128px;
  }
  .list {
    display: flex;
  }
  .item {
    margin-right: 40px;
    a {
      font-size: 17px;
    }
  }
  .language {
    margin-right: 48px;
  }

  @media (max-width: 1050px) {
    .item {
      margin-right: 20px;
    }
    .language {
      margin-right: 25px;
    }
    .custom-button {
      padding: 10px 20px;
    }
    .logo {
      img {
        width: 128px;
      }
    }
  }
  @media (max-width: 830px) {
    .item {
      margin-right: 15px;
      a {
        font-size: 16px;
      }
    }
    .language {
      margin-right: 15px;
      button {
        font-size: 16px !important;
      }
      svg {
        margin-left: 5px !important;
      }
    }
    .custom-button {
      padding: 10px 15px;
      font-size: 16px;
    }
    .logo {
      img {
        width: 128px;
      }
    }
  }

  @media (max-width: 768px) {
    position: initial;
    transform: none;
    padding: 20px;
    .actions {
      position: fixed;
      background-color: #fff;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: initial;
      transform: translateX(-130%);
      transition: 0.3s ease all;
      z-index: 10;
    }
    .burger {
      display: block;
    }
    .mobile-menu {
      display: block;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
      }
      .btns {
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 16px 0;
        margin-bottom: 49px;
        button {
          border: none;
          background-color: transparent;
          font-size: 20px;
          line-height: 27px;
          color: #00022b;
        }
        .btn-active {
          color: ${({ theme }) => theme.primary.light};
        }
      }
    }
    .list {
      display: initial;
      text-align: center;
    }
    .item {
      margin-left: 0;
      margin-bottom: 28px;
      a {
        font-size: 24px;
        font-weight: 500;
      }
    }
    .custom-button,
    .language {
      display: none;
    }
  }
  @media (max-width: 500px) {
    padding: 20px 13px;
    .mobile-menu {
      .top {
        padding: 20px 13px 20px 13px;
      }
    }
  }
`;
