import styled from "styled-components";

export const AboutStyled = styled.div`
  position: relative;
  margin-top: 200px;
  .section-title {
    text-align: center;
    margin-bottom: 90px;
  }
  .slick-dots {
    position: absolute;
    top: 0;
    right: 0px;
    width: 30%;
    background-color: #fff;
    li {
      width: 80px;
      height: 80px;
      /* overflow: hidden; */
      img {
        border-radius: 50%;
        width: 100%;
        height: 80px;
        object-fit: cover;
      }
    }
  }
  .item {
    display: flex !important;
    align-items: center;
    width: 70% !important;
    .image-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: end;
      height: 422px;
    }
    .dots {
      position: absolute;
      top: 0;
      right: 30px;
    }
    .client-image {
      width: 380px;
      height: 380px;
      margin-right: 67px;
      margin-left: 15px;
      filter: drop-shadow(10px 20px 50px rgba(0, 0, 0, 0.08));
      border-radius: 500px 0px 500px 500px;
      object-fit: cover;
    }
    .ellipse {
      position: absolute;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 9px solid #ff007a;
      z-index: -1;
    }
    .name {
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 18px;
    }
    .view {
      line-height: 160%;
      color: ${({ theme }) => theme.grey.dark};
    }
  }

  @media (max-width: 1100px) {
    .item {
      width: 75% !important;
      .image-wrapper {
        height: 362px;
      }
      .client-image {
        width: 320px;
        height: 320px;
        margin-right: 50px;
      }
    }
    .slick-dots {
      width: 25%;
    }
  }
  @media (max-width: 900px) {
    .item {
      .image-wrapper {
        height: 300px;
      }
      .client-image {
        width: 260px;
        height: 260px;
        margin-right: 50px;
      }
      .ellipse {
        width: 80px;
        height: 80px;
      }
    }
  }
  @media (max-width: 768px) {
    .item {
      width: 100% !important;
    }
    .slick-dots {
      display: flex;
      justify-content: space-between;
      width: 110px;
      height: fit-content;
      margin-top: 16px;
      top: 100%;
      left: 50%;
      margin-left: -55px;
      li {
        width: fit-content;
        height: fit-content;
        border-radius: 50%;
      }
      .slick-active {
        .dot-paging {
          background-color: ${({ theme }) => theme.primary.light};
        }
      }
    }
    .dot-paging {
      width: 16px;
      height: 16px;
      background-color: #e7f0fc;
      border-radius: 50%;
    }
  }
  @media (max-width: 600px) {
    .slider {
      overflow: hidden;
      height: 600px;
    }
    .item {
      display: block !important;
      text-align: center;
      .client-image {
        margin: 0 auto;
        margin-bottom: 30px;
      }
      .ellipse {
        width: 70px;
        height: 70px;
        left: 20%;
        bottom: 35px;
      }
    }
  }
  @media (max-width: 440px) {
    .item {
      .client-image {
        margin-bottom: 25px;
      }
      .ellipse {
        left: 15%;
        bottom: 30px;
      }
    }
  }
  @media (max-width: 420px) {
    .slider {
      height: 640px;
    }
  }
  @media (max-width: 370px) {
    .slider {
      height: 680px;
    }
    .item {
      .ellipse {
        left: 10%;
      }
    }
  }
`;
