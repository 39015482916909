import styled from "styled-components";

export const PlatformStyled = styled.div`
  position: relative;
  margin-top: 240px;
  .container {
    position: relative;
  }
  .bg-left {
    position: absolute;
    top: 37%;
    left: -27px;
    width: 175px;
    height: 175px;
    background-color: #fff5db;
    border-top-left-radius: 100px;
    z-index: -1;
  }
  .bg-right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-350px);
    width: 75%;
    height: 700px;
    border-top-left-radius: 150px;
    background-color: #f4f9ff;
    z-index: -1;
  }
  .dots {
    position: absolute;
    left: 12px;
    bottom: 69px;
    z-index: -2;
  }
  .ellipse {
    position: absolute;
    bottom: 36px;
    right: 0;
    z-index: -2;
  }
  .section-title {
    margin-bottom: 20px;
  }
  .description {
    font-size: 18px;
    line-height: 160%;
    color: ${({ theme }) => theme.grey.dark};
  }
  .card-wrapper {
    &:nth-child(even) {
      transform: translateY(-79px);
    }
  }
  .card {
    background-color: #fff;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
    border-radius: 40px;
    padding: 35px 26px 45px 26px;
    text-align: center;
    height: 83%;
    .image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      width: 120px;
      height: 120px;
      margin: 0 auto;
    }
    .image-wrapper1 {
      background-color: #f1f7ff;
    }
    .image-wrapper2 {
      background-color: #fff7e3;
    }
    .image-wrapper3 {
      background-color: #fff2f8;
    }
    .image-wrapper4 {
      background-color: #deffee;
    }
    h3 {
      font-size: 24px;
      margin-top: 25px;
      margin-bottom: 15px;
    }
    p {
      color: ${({ theme }) => theme.grey.light};
      line-height: 140%;
    }
  }
  @media (max-width: 899px) {
    margin-top: 180px;
    .cards {
      background-color: #f4f9ff;
      padding: 30px;
      border-radius: 48px 48px 0px 0px;
    }
    .dots {
      display: none;
    }
    .text {
      text-align: center;
      margin-bottom: 50px;
    }
    .card-wrapper {
      &:nth-child(even) {
        transform: translateY(0);
      }
    }
    .bg-left {
      left: 12px;
      top: -60px;
    }
    .bg-right {
      display: none;
    }
    .ellipse {
      top: 80px;
    }
  }
  @media (max-width: 768px) {
    margin-top: 120px;
    .cards {
      padding: 20px;
    }
    .card {
      padding: 30px 20px 40px 20px;
      h3 {
        font-size: 22px;
      }
    }
    .ellipse {
      top: 125px;
    }
  }
`;
