import styled from "styled-components";

export const ClientStyled = styled.div`
  margin-top: 180px;
  .text {
    text-align: center;
  }
  .first-slider {
    .slick-list {
      height: 180px !important;
      @media (max-width: 600px) {
        height: 140px !important;
      }
      @media (max-width: 400px) {
        height: 120px !important;
      }
    }
  }
  .images {
    display: flex;
    align-items: center;
    margin-top: 50px;
    justify-content: center;
    flex-wrap: wrap;
    .image {
      padding: 0 10px;
      width: 13%;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
      @media (max-width: 768px) {
        width: 16%;
      }
      @media (max-width: 550px) {
        width: 18%;
      }
      @media (max-width: 375px) {
        width: 23%;
      }
    }
  }

  .image-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    img {
      max-width: 230px;
      width: 100%;
    }
  }
  .section-title {
    margin-bottom: 12px;
  }
  .slider {
    overflow: hidden;
    .slick-list {
      margin: 0 -15px;
      height: 260px;
      @media (max-width: 768px) {
        height: 200px;
      }
      @media (max-width: 600px) {
        height: 180px;
      }
      @media (max-width: 400px) {
        height: 135px;
      }
      @media (max-width: 350px) {
        height: 185px;
      }
    }
    .slick-slide {
      margin: 0 15px;
    }
  }
  .description {
    font-size: 18px;
    line-height: 160%;
    color: ${({ theme }) => theme.grey.light};
  }
  @media (max-width: 899px) {
    margin-top: 90px;
    .section-title {
      text-align: center;
    }
    .description {
      text-align: center;
      margin-bottom: 45px;
    }
  }
`;
