import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../components/Navbar/Navbar";
import Intro from "../components/Intro/Intro";
import Apps from "../components/Apps/Apps";
import Platform from "../components/Platform/Platform";
import Tariff from "../components/Tariff/Tariff";
import Client from "../components/Client/Client";
import About from "../components/About/About";
import Try from "../components/Try/Try";
import Footer from "../components/Footer/Footer";

const Home = () => {
  return (
    <>
      <Navbar />
      <Intro />
      <Apps />
      <Platform />
      {/* <Tariff /> */}
      <Client />
      <About />
      <Try />
      <Footer />
    </>
  );
};

export default Home;
