import { Grid } from "@mui/material";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import { ClientStyled } from "./Client.style";
import Google from "./assets/google.svg";
import Asia from "./assets/asia-removebg-preview.png";
import Edify from "./assets/edify-removebg-preview.png";
import Elnur from "./assets/elnur-removebg-preview.png";
import Friends from "./assets/friends-removebg-preview.png";
import Grand from "./assets/grand-edu.jpg";
import Hdp from "./assets/hdp.jpg";
import IeltsZone from "./assets/ielts-zone.jpg";
import Ildam from "./assets/ildam-removebg-preview.png";
import IlmNuri from "./assets/ilm-nuri-removebg-preview.png";
import NurTalim from "./assets/nur-talim-removebg-preview.png";
import Repetitor from "./assets/repetitor-removebg-preview.png";
import Zukko from "./assets/zukko.jpg";
import Registan from "./assets/registan.png";
import Yuksalish from "./assets/yuksalish.png";
import RegistanIt from "./assets/registanIt.png";
import EnglishLife from "./assets/english-life.png";
import Abcd from "./assets/abcd.png";
import Altent from "./assets/altent.jpg";
import Zako from "./assets/zako.png";
import RiseUp from "./assets/riseup.jpg";

const Client = () => {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    infinite: true,
    speed: 4000,
    // nextArrow: renderNextButton(),
    // prevArrow: renderPrevButton(),
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <ClientStyled className="">
      <div className="text">
        <h2 className="section-title">{t("CLIENT.TITLE")}</h2>
        <p className="description">{t("CLIENT.DESCRIPTION")}</p>
      </div>

      <div className="slider first-slider mt-5">
        <Slider {...settings}>
          <div className="image-wrapper">
            <img src={RegistanIt} alt="registan it" />
          </div>
          <div className="image-wrapper">
            <img src={EnglishLife} alt="english life" />
          </div>
          <div className="image-wrapper">
            <img src={Abcd} alt="english life" />
          </div>
          <div className="image-wrapper">
            <img src={Registan} alt="registan" />
          </div>
          <div className="image-wrapper">
            <img src={Edify} alt="edify" />
          </div>
          <div className="image-wrapper">
            <img src={Ildam} alt="ildam" />
          </div>
        </Slider>
      </div>
      <div className="slider">
        <Slider {...settings}>
          <div className="image-wrapper">
            <img src={Yuksalish} alt="yuksalish" />
          </div>
          <div className="image-wrapper">
            <img src={Elnur} alt="elnur" />
          </div>
          <div className="image-wrapper">
            <img src={RiseUp} alt="riseup" />
          </div>
          <div className="image-wrapper">
            <img src={Zako} alt="zako" />
          </div>
          <div className="image-wrapper">
            <img src={Altent} alt="altent" />
          </div>
          <div className="image-wrapper">
            <img src={Asia} alt="asia" />
          </div>
          <div className="image-wrapper">
            <img src={Friends} alt="friends" />
          </div>
        </Slider>
      </div>
      <div className="slider mt-4">
        <Slider {...settings}>
          <div className="image-wrapper">
            <img src={Grand} alt="grand" />
          </div>
          <div className="image-wrapper">
            <img src={Hdp} alt="hdp" />
          </div>
          <div className="image-wrapper">
            <img src={IeltsZone} alt="ielts zone" />
          </div>
          <div className="image-wrapper">
            <img src={IlmNuri} alt="ilm nuri" />
          </div>
          <div className="image-wrapper">
            <img src={NurTalim} alt="nur ta'lim" />
          </div>
          <div className="image-wrapper">
            <img src={Repetitor} alt="repetitor" />
          </div>
          <div className="image-wrapper">
            <img src={Zukko} alt="zukko" />
          </div>
        </Slider>
      </div>
    </ClientStyled>
  );
};

export default Client;
