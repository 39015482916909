import styled from "styled-components";

export const IntroStyled = styled.div`
  height: calc(100vh - 106px);
  padding-top: 106px;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  .text {
    width: 49%;
    h1 {
      font-size: 48px;
      line-height: 64px;
      color: ${({ theme }) => theme.primary.dark};
    }
    .description {
      line-height: 24px;
      color: ${({ theme }) => theme.grey.rgba};
      margin: 24px 0;
    }
  }
  .download {
    display: flex;
    a {
      &:first-of-type {
        margin-right: 25px;
      }
    }
    img {
      width: 170px;
    }
  }
  .demo-modal {
    display: none;
  }
  .slider {
    width: 49%;
    .item {
      position: relative;
      display: flex !important;
      align-items: center;
      justify-content: center;
      min-height: 570px;
      img {
        width: 39%;
      }
      @media (min-width: 1400px) {
        min-height: 600px;
      }
    }
    .circle {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      z-index: -1;
    }
    .circle1 {
      background-color: ${({ theme }) => theme.primary.light};
    }
    .circle2 {
      background-color: #00e676;
    }
    .circle3 {
      background-color: #e8682c;
    }
    .circle4 {
      background-color: #185ad6;
    }
  }

  @media (max-width: 1250px) {
    .text {
      h1 {
        font-size: 45px;
        line-height: 55px;
      }
    }
    .slider {
      .item {
        min-height: 500px;
        img {
          width: 36%;
        }
      }
    }
  }
  @media (max-width: 1065px) {
    .text {
      h1 {
        font-size: 40px;
        line-height: 52px;
      }
    }
    .slider {
      .item {
        min-height: 450px;
      }
    }
  }
  @media (max-width: 945px) {
    .text {
      h1 {
        font-size: 35px;
        line-height: 45px;
      }
    }
    .slider {
      .item {
        min-height: 400px;
      }
    }
    .download img {
      width: 135px;
    }
  }
  @media (max-width: 830px) {
    .text {
      h1 {
        font-size: 30px;
        line-height: 40px;
      }
    }
    .slider {
      .item {
        min-height: 350px;
      }
    }
  }
  @media (max-width: 768px) {
    height: initial;
    padding-top: 100px;
    .container {
      flex-direction: column-reverse;
    }
    .text {
      width: 90%;
      text-align: center;
      margin-top: 20px;
      h1 {
        font-size: 35px;
        line-height: 45px;
      }
      .description {
        font-size: 18px;
      }
    }
    .slider {
      width: 70%;
      margin: auto;
      .item {
        min-height: 450px;
      }
    }
    .download {
      display: none;
    }
    .demo-modal {
      display: block;
      position: fixed;
      left: 30px;
      right: 30px;
      bottom: 10px;
      z-index: 20;
      .custom-button {
        width: 100%;
        padding: 10px 0;
      }
    }
  }
  @media (max-width: 700px) {
    .slider {
      .item {
        min-height: 400px;
      }
    }
  }
  @media (max-width: 600px) {
    .text {
      width: 100%;
      h1 {
        font-size: 30px;
        line-height: 40px;
      }
      .description {
        margin-top: 12px;
        margin-bottom: 32px;
      }
    }
    .slider {
      width: 80%;
      .item {
        min-height: 390px;
      }
    }
  }
  @media (max-width: 540px) {
    .slider {
      width: 90%;
      .item {
        min-height: 400px;
      }
    }
  }
  @media (max-width: 480px) {
    .text {
      width: 100%;
      h1 {
        font-size: 25px;
        line-height: 35px;
      }
    }
    .slider {
      width: 100%;
      overflow: hidden;
      .item {
        @media (max-width: 430px) {
          min-height: 370px;
          img {
            width: 38%;
          }
        }
        @media (max-width: 390px) {
          min-height: 330px;
          img {
            width: 38%;
          }
        }
        @media (max-width: 360px) {
          min-height: 320px;
          img {
            width: 40%;
          }
        }
        @media (max-width: 330px) {
          min-height: 300px;
          img {
            width: 40%;
          }
        }
      }
    }
  }
`;
