export const theme = {
  primary: {
    dark: "#232D4B",
    light: "#167ACF",
  },
  grey: {
    dark: "#565656",
    light: "#757575",
    rgba: "#000000cc",
  },
};
