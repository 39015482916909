import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FooterStyled } from "./Footer.style";
import Logo from "./assets/logo.svg";
import Location from "./assets/location.svg";
import Phone from "./assets/phone.svg";
import Facebook from "./assets/facebook.svg";
import Telegram from "./assets/telegram.svg";
import Instagram from "./assets/instagram.svg";
import Twitter from "./assets/twitter.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterStyled>
      <div className="container">
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={12} sm={6} lg={3} className="item">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className="item">
            <div className="location">
              <img src={Location} alt="location" />
              <p>{t("FOOTER.ADDRESS")}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className="item">
            <div className="phone-wrapper">
              <div className="phone">
                <img src={Phone} alt="phone" />
                <p>{t("FOOTER.PHONE")}</p>
              </div>
              <a href="tel: +998971993700" className="number">
                +998 97 199-37-00
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className="item">
            <p className="social-network">{t("FOOTER.SOCIAL_NETWORK")}:</p>
            <div className="links">
              <a href="" target="_blank">
                <img src={Facebook} alt="Facebook" />
              </a>
              <a href="" target="_blank">
                <img src={Telegram} alt="Telegram" />
              </a>
              <a href="" target="_blank">
                <img src={Instagram} alt="Instagram" />
              </a>
              <a href="" target="_blank">
                <img src={Twitter} alt="Twitter" />
              </a>
            </div>
          </Grid>
        </Grid>
      </div>
    </FooterStyled>
  );
};

export default Footer;
