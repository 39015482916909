import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { DemoModalStyled } from "./DemoModal.style";
import CloseIcon from "./assets/closeIcon.svg";

const DemoModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  window.addEventListener("message", function (event) {
    if (event.origin === "https://edutizim.uz") {
      return setOpen(false);
    }
  });

  return (
    <DemoModalStyled>
      <div className="btn-wrapper">
        <button className="custom-button" onClick={() => setOpen(true)}>
          {t("MODAL.DEMO")}
        </button>
      </div>
      <Helmet></Helmet>
      {open && (
        <div onClick={() => setOpen(false)} className="modal">
          <div onClick={() => setOpen(false)} className="close">
            <img src={CloseIcon} alt="close" className="pointer" />
          </div>
          <iframe
            id="inlineFrameExample"
            title="Inline Frame Example"
            src="https://forms.amocrm.ru/rvcxrrl"
          ></iframe>
          {/* <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="top">
              <h2 className="font-600">Demo versiya so'rash</h2>
              <div onClick={() => setOpen(false)}>
                <img src={CloseIcon} alt="close" className="pointer" />
              </div>
            </div>
            <form onSubmit={handleSubmit(submit)}>
              <Input
                params={{ ...register("firstName") }}
                className="input "
                placeholder={t("MODAL.NAME")}
              />
              <PhoneInput
                placeholder="+998 -- --- -- --"
                params={{
                  ...register("phoneNumber", {
                    required: {
                      value: true,
                      message: "required",
                    },
                    minLength: {
                      value: 9,
                      message: "minimu 9",
                    },
                    pattern: {
                      value: phoneNumberPattern,
                      message: "maximum 9",
                    },
                  }),
                }}
                error={formState.errors.phoneNumber}
                className="input phoneNumberInput"
              />
              <Input
                params={{ ...register("firstName") }}
                className="input "
                placeholder={t("MODAL.COMPANY_NAME")}
              />
              <button className="custom-button">{t("MODAL.SEND")}</button>
            </form>
          </div> */}
        </div>
      )}
    </DemoModalStyled>
  );
};

export default DemoModal;
