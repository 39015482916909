import styled from "styled-components";

export const AppsStyled = styled.div`
  margin-top: 180px;
  .section-title {
    text-align: center;
    margin-bottom: 90px;
  }
  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 65%;
    margin: auto;
    margin-bottom: 300px;
    &:nth-child(even) {
      flex-direction: row-reverse;
      .text {
        text-align: right;
      }
      .image-wrapper {
        display: flex;
        /* flex-direction: column; */
        /* align-items: center; */
        justify-content: end;
        img {
          /* display: flex; */
          /* align-self: flex-end; */
        }
      }
      .circle {
        position: absolute;
        right: -180px;
        bottom: -60px;
        width: 380px;
        height: 380px;
        border-radius: 50%;
        z-index: -1;
      }
    }
    &:nth-child(odd) {
      .circle {
        position: absolute;
        left: -180px;
        bottom: -60px;
        width: 380px;
        height: 380px;
        border-radius: 50%;
        z-index: -1;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    .image-wrapper {
      width: 44%;
      .circle1 {
        background-color: rgba(22, 122, 207, 0.2);
      }
      .circle2 {
        background-color: rgba(147, 255, 122, 0.7);
      }
      .circle3 {
        background-color: rgba(235, 81, 11, 0.2);
      }
      .circle4 {
        background-color: rgba(22, 122, 207, 0.2);
      }
    }
    .text {
      width: 53%;
    }
    .title {
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 16px;
    }
    .description {
      font-size: 19px;
      line-height: 27px;
    }
  }
  @media (max-width: 1300px) {
    .item {
      width: 75%;
      &:nth-child(even) {
        .circle {
          right: -60px;
        }
      }
      &:nth-child(odd) {
        .circle {
          left: -60px;
        }
      }
    }
  }
  @media (max-width: 1080px) {
    .item {
      .image-wrapper {
        display: flex;
        justify-content: end;
      }
      width: 85%;
      &:nth-child(even) {
        .image-wrapper {
          display: flex;
          justify-content: start;
        }
        .circle {
          right: 0px;
        }
      }
      &:nth-child(odd) {
        .circle {
          left: 0px;
        }
      }
    }
  }
  @media (max-width: 980px) {
    margin-top: 100px;
    .list {
      overflow: hidden;
    }
    .item {
      width: 100%;
      margin-bottom: 200px;
      .circle {
        width: 340px !important;
        height: 340px !important;
      }
    }
  }
  @media (max-width: 768px) {
    margin-top: 60px;
    .item {
      .circle {
        width: 300px !important;
        height: 300px !important;
      }
      .title {
        font-size: 28px;
        line-height: 36px;
      }
      .description {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  @media (max-width: 650px) {
    margin-top: 70px;
    .section-title {
      margin-bottom: 50px;
    }
    /* .list {
      height: 650px;
      overflow: auto;
    } */
    .item {
      display: block;
      margin-bottom: 50px !important;
      .circle {
        left: 50% !important;
        top: 50%;
        transform: translate(-150px, -150px) !important;
      }
      &:nth-child(even) {
      }
      .title {
        font-size: 24px;
        margin-bottom: 11px;
      }
      .image-wrapper {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center !important;
        img {
          width: 40%;
        }
      }
      .text {
        width: 100%;
        margin-top: 40px;
        text-align: center !important;
      }
    }
  }
  @media (max-width: 500px) {
    .item {
      .image-wrapper {
        img {
          width: 50%;
        }
      }
    }
  }
  @media (max-width: 380px) {
    .item {
      .image-wrapper {
        img {
          width: 60%;
        }
      }
    }
  }
`;
