import styled from "styled-components";

export const TryStyled = styled.div`
  position: relative;
  margin-top: 180px;
  padding: 56px 38px 39px 38px;
  .bg-left {
    position: absolute;
    bottom: 0;
    left: 8px;
    width: 175px;
    height: 175px;
    background-color: #fff5db;
    border-bottom-left-radius: 100px;
    z-index: -1;
  }
  .bg-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 42%;
    height: 100%;
    background-color: ${({ theme }) => theme.primary.light};
    border-radius: 110px 75px 75px 150px;
  }
  .dots {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: -1;
  }
  .list {
    position: relative;
    background-color: #f4f9ff;
    border-radius: 75px;
    padding: 95px;
    align-items: center;
    justify-content: space-between;
  }
  .description {
    font-size: 18px;
    line-height: 160%;
    color: ${({ theme }) => theme.grey.light};
    margin-top: 12px;
  }
  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    height: 80px;
    background-color: #fff;
    box-shadow: 10px 20px 50px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    border: 1px solid #f1f1f1;
    padding: 0 40px;
    /* z-index: 1; */
    /* .input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 18px;
      line-height: 22px;
      padding-left: 25px;
    }
    .send-btn {
      padding: 20px 40px;
      font-size: 16px;
      line-height: 22px;
      border-radius: 60px;
      border: none;
      background-color: ${({ theme }) => theme.primary.light};
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    } */
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  @media (max-width: 1305px) {
    overflow-x: hidden;
    .list {
      padding: 70px;
    }
  }
  @media (max-width: 1100px) {
    padding: 0;
    .list {
      padding: 50px;
    }
    .input-wrapper {
      /* height: 60px; */
      /* padding: 0 5px; */
      .send-btn {
        padding: 15px 26px;
      }
    }
  }
  @media (max-width: 899px) {
    margin-top: 110px;
    .bg-right {
      display: none;
    }
    .bg-right-mobile {
      position: absolute;
      bottom: 0;
      right: 50px;
      width: 60%;
      height: 50%;
      background-color: ${({ theme }) => theme.primary.light};
      border-radius: 110px 75px 75px 150px;
      z-index: 2;
    }
    .input-wrapper {
      margin-right: 50px;
      z-index: 3;
    }
    .section-title {
      text-align: center;
    }
    .description {
      text-align: center;
      margin-bottom: 80px;
    }
    .dots {
      top: 35%;
      z-index: 1;
    }
  }
  @media (max-width: 660px) {
    margin-top: 80px;
    .list {
      padding: 40px 10px;
    }
    .bg-right-mobile {
      position: absolute;
      bottom: 20px;
      right: 10px;
      width: 70%;
      height: 50%;
      background-color: ${({ theme }) => theme.primary.light};
      border-radius: 110px 75px 75px 150px;
      z-index: 2;
    }
    .input-wrapper {
      margin-right: 20px;
      margin-bottom: 30px;
      /* height: 53px; */
      .input {
        padding-left: 10px;
        font-size: 16px;
      }
    }
    .description {
      margin-bottom: 110px;
    }
    .send-btn {
      padding: 10px 15px !important;
    }
  }
`;
